import type { ResolveFn } from "@angular/router";
import { inject } from "@angular/core";
import { AuthenticationService } from "../core/authentication.service";
import { HttpErrorResponse } from "@angular/common/http";

export const resetPasswordResolver: any = (route, state) => {
  const authService: AuthenticationService = inject(AuthenticationService);
  const verificationCode = route.params["verificationCode"];

  if (!verificationCode) {
    return {
      error: true,
      data: [],
    };
  }

  authService.codeVerification(verificationCode).subscribe(
    (_: any) => {
      return {
        error: false,
        data: [],
      };
    },
    (error: HttpErrorResponse) => {
      return {
        error: true,
        data: error.error.errors,
      };
    }
  );

  return true;
};
